<template>
  <el-dialog title="选择资源保存路径" :modal-append-to-body="true" width="800px"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <div class="dialogHeight" v-loading="loading">
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
        <template class="custom-tree-node" slot-scope="{ node, data }">
          <div class="flex_l_c">
            <el-image fit="contain" :src="require('@/assets/img/folder.png')"
                      class="itemImg"></el-image>
            <div style="padding-left: 10px">{{ node.label }}</div>
          </div>
        </template>
      </el-tree>
    </div>
    <div class="flex_b_c" style="margin-top: 20px">
      <el-button size="small" @click="addFolder()">新建文件夹</el-button>
      <div>
        <el-button type="primary" size="small" @click="sureMove()" v-no-more-click>确定</el-button>
        <el-button size="small" @click="handleClose()">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "fileMove",
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'folderName'
      },
      regex: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,
      selectFolder: {},
      moveIds: [],
      moveResourceId:'',
    }
  },

  methods: {
    init(ids,resourceId) {
      this.moveIds = ids
      this.moveResourceId = resourceId
      this.visible = true
      this.getAllFolder()
    },

    //获取所有文件夹
    getAllFolder() {
      this.loading = true
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkGetAllFolders, {}, 'post').then(data => {
        if (data && data.status) {
          this.data = data.data
          this.data.forEach(item => {
            this.$set(item, 'folderName', item.resourceName)
          })
        } else {
          this.$message.error(data.msg)
        }
        this.loading = false
      })
    },

    //选择文件夹
    handleNodeClick(data) {
      this.selectFolder = data
    },

    //新建文件夹
    addFolder() {
      if (!this.selectFolder.id) {
        this.$message.warning('请选择要创建在哪个文件夹下')
        return
      }
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value || value.length == 0) {
            return '文件夹名称不能为空'
          }
          if (value.length > 60) {
            return '文件夹名称最长为60个字符'
          }
          if (!this.regex.test(value)) {
            return '文件夹名称不正确'
          }
        },
        inputErrorMessage: '文件夹名称不正确',
        inputPlaceholder: '请输入文件夹名称(限60字)',
      }).then(({value}) => {
        this.$axios(this.api.digital.comprehensiveResourcesFileLinkSave, {
          files: [],
          folderFlag: 0,
          folderName: value,
          parentId: this.selectFolder.id,
          resourceId: this.selectFolder.resourceId,
        }, 'post').then((res) => {
          if (res.status) {
            this.$message.success('添加成功')
            this.getAllFolder()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    //确认移动
    sureMove() {
      if (!this.selectFolder.id) {
        this.$message.warning('请选择要移动到的文件夹')
        return
      } else {
        //移动
        this.$axios(this.api.digital.comprehensiveResourcesFileLinkMove, {
          newId: this.selectFolder.id,
          newResourceId: this.selectFolder.resourceId,
          oldIds:this.moveIds,
          // oldResourceId:this.moveResourceId,
        }, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(`移动成功`)
            this.$emit('refreshList')
            this.handleClose()
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },

    handleClose() {
      this.data = []
      this.selectFolder = {}
      this.visible = false
    },
  },
}
</script>

<style scoped>
.dialogHeight {
  height: 400px;
  overflow-y: auto;
}

.itemImg {
  height: 20px;
}
</style>